import * as React from "react";
import { styled } from "@mui/material";
import MuiAvatar from "@mui/material/Avatar";

const AvatarWrapper = styled("div")(({ theme }) => ({
  border: "5px solid",
  borderColor: theme.palette.background.default,
  borderRadius: "50%",
}));

function Avatar(props) {
  const sx = Object.assign({ border: "2px solid black" }, props.sx);

  return (
    <AvatarWrapper>
      <MuiAvatar {...props} sx={sx} />
    </AvatarWrapper>
  );
}

export default Avatar;
