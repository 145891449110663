import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

const ProductHeroLayoutRoot = styled("section")(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.common.black,
  position: "relative",
  display: "flex",
  alignItems: "center",
  height: "calc(100vh - 170px)",
  marginTop: 70,
}));

function ProductHeroLayout(props) {
  const { children } = props;

  return (
    <ProductHeroLayoutRoot>
      <Container
        sx={{
          // mt: 10,
          // mb: 14,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {children}
      </Container>
    </ProductHeroLayoutRoot>
  );
}

ProductHeroLayout.propTypes = {
  children: PropTypes.node,
  sxBackground: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default ProductHeroLayout;
