import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

const FooterLayout = styled("section")(({ theme }) => ({
  color: theme.palette.common.black,
  position: "relative",
  display: "flex",
  alignItems: "center",
  minHeight: 100,
}));

function Footer(props) {
  const { sxBackground, children } = props;

  return (
    <FooterLayout>
      <Container
        sx={{
          pt: 2,
          pb: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2} />
          <Grid item xs={8} textAlign="center">
            {children}
          </Grid>
          <Grid item xs={2}>
            &copy; 2022 Liam Bowers
          </Grid>
        </Grid>
      </Container>
    </FooterLayout>
  );
}

Footer.propTypes = {
  children: PropTypes.node,
};

export default Footer;
