import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ProductHeroLayout from "./ProductHeroLayout";
import Avatar from "../components/Avatar";
import NewsletterSubscribe from "./NewsletterSubscribe";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        // backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      <Avatar
        alt="Liam Bowers"
        src="/static/img/headshot_color_sm_sq.jpg"
        sx={{ width: 128, height: 128 }}
      />
      <Box textAlign="center">
        <Typography py={2}>
          Holy crap, you found my website! Welcome!!
        </Typography>
        <Typography py={1} color="inherit" variant="h1">
          I’m working on a new song!
        </Typography>
        <Typography py={2} color="inherit" variant="h4">
          It's still a little rough, but do you wanna hear how it's going?
        </Typography>
        <NewsletterSubscribe />
        <Typography py={2}>
          That's where I share new stuff I'm working on and nerd out about
          music, art, and that sort of thing.
        </Typography>
      </Box>
    </ProductHeroLayout>
  );
}
