import * as React from "react";

import withRoot from "./modules/withRoot";
import AppAppBar from "./modules/views/AppAppBar";
import ProductHero from "./modules/views/ProductHero";
import Footer from "./modules/views/Footer";
import Socials from "./modules/views/Socials";

function App() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
      <Footer>
        <Socials />
      </Footer>
    </React.Fragment>
  );
}

export default withRoot(App);
