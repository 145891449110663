import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Button from "../components/Button";
import MailchimpForm from "./MailchimpForm";

export default function NewsletterSubscribe() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // const action = "https://liambowers.us14.list-manage.com/subscribe/post";
  // const u = "5142fb1acd0372dbd3709e8d6";
  // const id = "615f1aec2f";

  // <input type="email"
  //        autocapitalize="off"
  //        autocorrect="off"
  //        name="MERGE0" id="MERGE0" size="25" value="">

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={handleClickOpen}
      >
        Sign up to my Newsletter!
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <MailchimpForm closeAction={handleClose} />
      </Dialog>
    </section>
  );
}
