import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContent from "@mui/material/DialogContent";

const formAction = "https://liambowers.us14.list-manage.com/subscribe/post";
const emailPattern = /^[^@]+@[^@]+\.[^@]+$/;

const getJsonUrl = (url) => url.replace("/post?", "/post-json?");

const serializeForm = (formRef) => {
  const data = new FormData(formRef);
  const params = new URLSearchParams(data);
  let url = formRef.getAttribute("action");
  url += (~url.indexOf("?") ? "&" : "?") + params.toString();
  url = url.replace("?&", "?");
  return getJsonUrl(url);
};

function MailchimpForm(props) {
  const [emailValue, setEmailValue] = React.useState("");
  const [emailValid, setEmailValid] = React.useState(false);
  const [nameValue, setNameValue] = React.useState("");
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [successStatus, setSuccessStatus] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const disableForm = !emailValid;

  function handleClose() {
    props.closeAction();
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (disableForm) return;
    const url = serializeForm(event.target);
    try {
      setLoading(true);
      setErrorStatus(false);
      setHelperText("sending...");
      const data = await jsonp(url);
      if (data.result !== "success") {
        setErrorStatus(true);
        setHelperText(data.msg);
      } else {
        setSuccessStatus(true);
      }
    } catch (error) {
      setErrorStatus(true);
      setHelperText(String(error));
    }
    setLoading(false);
  }

  function handleNameChange(event) {
    const nameValue = event.target.value;
    setNameValue(nameValue);
  }

  function handleEmailChange(event) {
    const emailValue = event.target.value;
    setEmailValue(emailValue);
    setEmailValid(emailPattern.test(emailValue));
  }

  return successStatus ? (
    <Box>
      <DialogTitle>Almost Finished!</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          {`Yay, Thanks for signing up! To complete the subscription process, please click the link in the email I just sent you.`}
        </Typography>
        <Typography gutterBottom>
          {`P.S. If you don't see anything from me after a few minutes, check the "Promotions" tab, or, gahh, "Spam" :(`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Box>
  ) : (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      autoCorrect="off"
      action={formAction}
      method="POST"
      onSubmit={handleSubmit}
    >
      <DialogTitle>Sign up to my Newsletter!</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          tabIndex={0}
          value={nameValue}
          onChange={handleNameChange}
          margin="dense"
          id="MERGE1"
          name="MERGE1"
          label="Name"
          type="email"
          variant="standard"
          error={errorStatus}
        />
        <TextField
          fullWidth
          tabIndex={1}
          value={emailValue}
          onChange={handleEmailChange}
          margin="dense"
          id="MERGE0"
          name="MERGE0"
          label="Email Address"
          type="email"
          variant="standard"
          error={errorStatus}
          helperText={helperText}
        />
        <DialogActions>
          <Button tabIndex={3} onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            tabIndex={2}
            disabled={disableForm}
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
            color="secondary"
            variant="contained"
          >
            Subscribe
          </LoadingButton>
        </DialogActions>
      </DialogContent>
      <input type="hidden" name="u" value="5142fb1acd0372dbd3709e8d6" />
      <input type="hidden" name="id" value="615f1aec2f" />
    </Box>
  );
}

function jsonp(uri) {
  return new Promise(function (resolve, reject) {
    var id = "_" + Math.round(10000 * Math.random());
    var callbackName = "jsonp_callback_" + id;
    window[callbackName] = function (data) {
      delete window[callbackName];
      var ele = document.getElementById(id);
      ele.parentNode.removeChild(ele);
      resolve(data);
    };

    var src = uri + "&c=" + callbackName;
    var script = document.createElement("script");
    script.src = src;
    script.id = id;
    script.addEventListener("error", reject);
    (
      document.getElementsByTagName("head")[0] ||
      document.body ||
      document.documentElement
    ).appendChild(script);
  });
}

export default MailchimpForm;
