import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import MuiToolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  height: 70,
}));

function AppAppBar() {
  return (
    <MuiAppBar elevation={0} position="fixed">
      <Toolbar>
        <Typography
          variant="h1"
          color="inherit"
          sx={{ margin: "0 auto", fontSize: 24 }}
        >
          {"liam bowers"}
        </Typography>
      </Toolbar>
    </MuiAppBar>
  );
}

export default AppAppBar;
