import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {
  FacebookIcon,
  InstagramIcon,
  TikTokIcon,
  TwitterIcon,
  YouTubeIcon,
} from "../components/Icons";

const SocialsRoot = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.dark,
  alignItems: "center",
}));

const SocialLink = styled(Link)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  transition: "transform 0s cubic-bezier(0.23, 1, 0.32, 1) 0s",
  display: "inline-block",
  height: 32,
  zIndex: 0,
  "&:hover .MuiSvgIcon-root": {
    transform: "scale(1.075)",
  },
  "& .MuiSvgIcon-root": {
    width: "100%",
    height: "100%",
    fill: theme.palette.primary,
  },
}));

function Socials() {
  return (
    <SocialsRoot>
      {/* <Typography
        color="inherit"
        align="center"
        variant="h5"
        fontWeight="medium"
        sx={{ padding: "1em 0" }}
      >
        @liamondrop
      </Typography> */}
      <SocialLink
        href="https://www.youtube.com/channel/UCZIRnU_MHB3YxSDHdBndu1w"
        target="_blank"
        aria-label="@liamondrop youtube"
      >
        <YouTubeIcon />
      </SocialLink>
      {/* <SocialLink
        href="https://www.tiktok.com/@liamondrop"
        target="_blank"
        aria-label="@liamondrop tiktok"
      >
        <TikTokIcon />
      </SocialLink> */}
      <SocialLink
        href="https://twitter.com/liamondrop"
        target="_blank"
        aria-label="@liamondrop twitter"
      >
        <TwitterIcon />
      </SocialLink>
      <SocialLink
        href="https://www.instagram.com/liamondrop/"
        target="_blank"
        aria-label="@liamondrop instagram"
      >
        <InstagramIcon />
      </SocialLink>
      <SocialLink
        href="https://www.facebook.com/realliamondrop"
        target="_blank"
        aria-label="@liamondrop facebook"
      >
        <FacebookIcon />
      </SocialLink>
    </SocialsRoot>
  );
}

export default Socials;
